<template>
  <div class="hk-content">
    <hk-swiper class="hk-discount" :options="options" :index.sync="index">
      <hk-swiper-slide v-for="category in data" :key="category.id">
        <div class="hk-discount__item">
          <div class="hk-discount__header">
            <!-- <h4>{{ $t('discount.title') }}</h4> -->
            <h4>{{ category.name }}</h4>
            <span>{{ category.start_time.split(' ')[0] }} - {{ category.end_time.split(' ')[0] }}</span>
          </div>
          <ul class="hk-discount__body">
            <li>
              <p>{{ $t('discount.desc') }}：</p>
              <!-- <span>{{ $t('discount.newDesc', [category.num]) }}</span> -->
              <span>{{ category.desc }}</span>
            </li>
            <li>
              <p>{{ $t('discount.price') }}：</p>
              <ul class="hk-discount__list">
                <li v-for="item in category.product" :key="item.id" class="hk-discount__list-item">
                  <div>
                    <p class="hk-discount__name">{{ item.product_category_name }} {{ item.name }}</p>
                    <p>
                      {{ $t('discount.originalPrice') }} HK${{ item.price }}，{{ $t('discount.price') }}
                      <span>HK$ {{ item.group_price }}</span>
                    </p>
                  </div>
                  <div v-if="item.is_sale_out != 1">
                    <p class="hk-discount__label">{{ $t('discount.quantity') }}</p>
                    <div>
                      <el-input-number
                        v-model="item.num"
                        :min="0"
                        :max="item.reserve"
                        class="input-number"
                        :controls="false"
                      />
                      <!---
                      <el-input-number
                        v-model="amount[category.id - 1][item.name].quantity"
                        :min="0"
                        :max="amount[category.id - 1][item.name].inventory"
                        controls-position="right"
                      />
                      --->
                      <span>{{ $t('common.carton') }}</span>
                      <br class="hk-en" />
                      <br class="hk-mobile" />
                      <i>（{{ $t('common.stock', [item.reserve]) }}）</i>
                    </div>
                  </div>
                  <div v-if="item.is_sale_out == 1">
                    <p class="hk-discount__label">{{ $t('discount.quantity') }}</p>
                    <div>
                      <br class="hk-en" />
                      <br class="hk-mobile" />
                      <i>（{{ $t('common.outOfStock') }}）</i>
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
          <div class="hk-discount__button">
            <el-button
              type="primary"
              @click="addBatchToCart(category.id)"
            >{{ $t('common.purchase') }}</el-button>
            <el-button @click="addBatchToFavourite(category.id)">{{ $t('common.favorite') }}</el-button>
          </div>
        </div>
      </hk-swiper-slide>
    </hk-swiper>
    <div class="hk-discount__prev hk-desktop" />
    <div class="hk-discount__next hk-desktop" />

    <el-dialog class="hk-alert" :visible.sync="reviewDialogShow">
      <div>
        <p>{{ alertMessage }}</p>
      </div>
      <div class="hk-alert__button">
        <el-button type="primary" @click="reviewDialogShow = false">{{ $t('common.confirm') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { HkSwiper, HkSwiperSlide } from '@/components/swiper'
export default {
  name: 'HkDiscount',
  components: {
    HkSwiper,
    HkSwiperSlide,
  },
  data() {
    this.getGroupBuyList()
    return {
      index: 0,
      groupBuyList: [],
      options: {
        grabCursor: true,
        keyboard: true,
        slidesPerView: 1,
        simulateTouch: true,
        pagination: true,
        navigation: {
          prevEl: '.hk-discount__prev',
          nextEl: '.hk-discount__next',
        },
        breakpoints: {
          1280: {
            slidesPerView: 2,
          },
        },
      },
      amount: [],
      reviewDialogShow: false,
      alertMessage: 'ss'
    }
  },
  computed: {
    data() {
      return this.groupBuyList
    },
  },
  methods: {
    getGroupBuyList(page = 1, limit = 10) {
      let self = this
      let rootApi = self.$store.state.config.apiRoot
      let apiCall =
        rootApi +
        '/api/group_by/list?lang=' +
        self.$store.state.config.language +
        '&page=' +
        page +
        '&limit=' +
        limit
      this.axios
        .get(apiCall)
        .then(function (response) {
          let productList = response.data.data.list
          productList.forEach((category) => {
            category.product.forEach((item) => {
              item.num = 0
            })
            let desc_string = ''
            let productCount = 1
            category.detail.forEach((desc) => {
              if (productCount == 1) {
                desc_string =
                  desc_string +
                  self.$t('discount.description_general', [
                    desc.prodcut_category_name,
                    desc.num,
                  ])
              } else {
                desc_string =
                  desc_string +
                  ' + ' +
                  self.$t('discount.description_general', [
                    desc.prodcut_category_name,
                    desc.num,
                  ])
              }
              productCount++
            })
            category.description = desc_string
          })
          self.groupBuyList = self.groupBuyList.concat(response.data.data.list)
          console.log(self.groupBuyList)
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    addBatchToCart(categoryId) {
      let self = this
      let products = []
      let batchList = self.groupBuyList.filter((item) => item.id == categoryId)
      batchList[0].product.forEach(function (item) {
        let groupBuyItem = {}
        if (item.num != 0) {
          groupBuyItem = {
            product_id: item.product_id,
            num: item.num,
          }
          products = products.concat(groupBuyItem)
        }
      })
      if (products.length == 0) {
        self.alertMessage = self.$t('cart.fillQuantity')
        self.reviewDialogShow = true
        return
      }
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/shopping/batchAdd'
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }
      let formData = new FormData()
      formData = {
        products: JSON.stringify(products),
      }
      this.axios
        .post(apiCall, formData, header)
        .then(function (response) {
          let code = response.data.code
          switch (code) {
            case 0:
              self.updateCart()
              self.alertMessage = self.$t('detail.addSuccess')
              self.reviewDialogShow = true
              break
            case 402:
              self.alertMessage = self.$t('common.underReview')
              self.reviewDialogShow = true
              break
            case 403:
              self.$store.commit('openLoginDialog')
              break
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    addBatchToFavourite(categoryId) {
      let self = this
      let batchList = self.groupBuyList.filter((item) => item.id == categoryId)
      let product_ids = []
      batchList[0].product.forEach(function (item) {
        product_ids = product_ids.concat(item.product_id)
      })
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/product/batchLike'
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }
      let formData = new FormData()
      formData = {
        product_ids: product_ids.join(),
      }
      this.axios
        .post(apiCall, formData, header)
        .then(function (response) {
          let code = response.data.code
          switch (code) {
            case 0:
              self.alertMessage = self.$t('detail.addSuccess')
              self.reviewDialogShow = true
              break
            case 402:
              self.alertMessage = self.$t('common.underReview')
              self.reviewDialogShow = true
              break
            case 403:
              self.$store.commit('openLoginDialog')
              break
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    updateCart() {
      let self = this
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/shopping/productNum'
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }
      this.axios.get(apiCall, header).then(function (response) {
        let num = response.data.data.product_num
        self.$store.commit('updateCartNum', num)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import './styles/discount';
</style>
<style>
.hk-alert {
  height: 600px;
}
.hk-alert .hk-alert__button {
  padding-top: 30px;
  padding-bottom: 30px;
}
.hk-alert .el-dialog__body {
  padding: 1rem;
}
.input-number {
  width: 100px;
  padding-right: 10px;
}
</style>
