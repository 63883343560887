import { render, staticRenderFns } from "./discount.vue?vue&type=template&id=553a53a5&scoped=true&"
import script from "./discount.vue?vue&type=script&lang=js&"
export * from "./discount.vue?vue&type=script&lang=js&"
import style0 from "./discount.vue?vue&type=style&index=0&id=553a53a5&lang=scss&scoped=true&"
import style1 from "./discount.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "553a53a5",
  null
  
)

export default component.exports